import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

const GooglePayHero = () => {
  return (
    <ContentContainer
      padding="80px 0"
      mPadding="80px 24px"
      maxWidth="1020px"
    >
      <GooglePayHeroWrapper>
        <div className="col">
          <h1>
            SpendOne with Google Pay<span className="TM">TM</span>
          </h1>
          <p>
            Google Pay is a fast, simple way to pay online, in apps, and in
            stores. It brings together everything you need at checkout and
            protects your payment information with multiple layers of security.
          </p>
          <a href="#app-download">
            <HomeButton
              height={BUTTON_SIZE.HEIGHT_L}
              padding={BUTTON_SIZE.PADDING_L}
              fontSize={BUTTON_SIZE.FONT_SIZE_L}
              background={BUTTON_COLOR.GREEN}
              backgroundHover={BUTTON_COLOR.GREEN_HOVER}
              color={BUTTON_COLOR.TEXT_COLOR_WHITE}
              text="Download The SocietyOne App"
              margin="0"
              handleClick={() => {}}
            />
          </a>
        </div>
        <div className="col heroImg">
          <StaticImage
            src="../../assets/images/google-pay-hero.jpeg"
            alt="google-pay-hero-image"
            placeholder="none"
          />
        </div>
      </GooglePayHeroWrapper>
    </ContentContainer>
  )
}

const GooglePayHeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    max-width: 500px;
    margin-bottom: 44px;
  }
  .logo {
    max-width: 300px;
    margin-top: 64px;
  }
  .heroImg {
    width: 100%;
    max-width: 400px;
  }
  .col {
    margin-bottom: 40px;
  }
  p {
    max-width: 500px;
    margin-bottom: 44px;
  }
  .TM {
    display: inline-block;
    font-size: 16px;
    transform: translate(4px,-29px);
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    h1 {
      text-align: center;
    }
    button {
      margin: 0 auto;
    }
    .logo {
      margin-left: auto;
      margin-right: auto;
    }
    .TM {
      transform: translate(4px,-25px);
      font-size: 14px;
    }
  }
`

export default GooglePayHero
