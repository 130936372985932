import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

const GPayFAQs = () => {
  return (
    <ContentContainer
      padding="80px 0"
      maxWidth="1020px"
      mPadding="80px 24px"
    >
      <GPayFAQsWrapper>
        <h2>Frequently Asked Questions</h2>
        <div className="para">
          Get answers to all your personal finance questions.
        </div>
        <HomeButton
          height={BUTTON_SIZE.HEIGHT_L}
          padding={BUTTON_SIZE.PADDING_L}
          fontSize={BUTTON_SIZE.FONT_SIZE_L}
          background="var(--base-navy)"
          color={BUTTON_COLOR.TEXT_COLOR_WHITE}
          text="View FAQs"
          handleClick={() => navigate('/marketplace/help-spendsave/')}
        />
      </GPayFAQsWrapper>
    </ContentContainer>
  )
}

const GPayFAQsWrapper = styled.div`
  text-align: center;
  .para {
    margin: 24px 0 40px;
    font-size: 20px;
    line-height: 1.5;
  }
`

export default GPayFAQs
