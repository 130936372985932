import React from 'react'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import GooglePayHero from '../components/ForGooglePay/GooglePayHero'
import AppQRCode from '../components/UI/AppQRCode'
import EncryptedByGoogle from '../components/ForGooglePay/EncryptedByGoogle'
import HowToSetUpGPay from '../components/ForGooglePay/HowToSetUpGPay'
import HowToUseGPay from '../components/ForGooglePay/HowToUseGPay'
import WhereToGPay from '../components/ForGooglePay/WhereToGPay'
import GPayFAQs from '../components/ForGooglePay/GPayFAQs'
import GPayTandC from '../components/ForGooglePay/GPayTandC'

const GooglePay = ({ path }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "SpendOne with Google Pay",
    "datePublished": "2022-04-19",
  }
  return (
    <Layout showTandC={false}>
      <Seo title="Pay Safer & Faster With Google Pay" path={path} description="Pay safer and faster with Google Pay. Google Pay is a fast, simple way to pay online, in apps, and in stores. Sign up with SocietyOne Today." structuredData={structuredData}/>
      <GooglePayHero />
      <EncryptedByGoogle />
      <HowToSetUpGPay />
      <HowToUseGPay />
      <WhereToGPay />
      <hr className="solid" />
      <GPayFAQs />
      <GPayTandC />
      <AppQRCode google />
    </Layout>
  )
}

export default GooglePay