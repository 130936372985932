import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import ContentContainer from '../UI/ContentContainer'

const WhereToGPay = () => {
  return (
    <ContentContainer
      mainPadding="80px 0"
      maxWidth="1020px"
      smallScreenPadding="80px 20px"
    >
      <WhereToGPayWrapper>
        <h2>Where to use Google Pay</h2>
        <div className="para extra-large">
          Google Pay is accepted at stores, apps, and websites around the world.
          You can use Google Pay wherever you see these symbols.
        </div>
        <div className="icon">
          <StaticImage
            src="../../assets/images/where-to-google-pay.png"
            alt="where-to-google-pay-image"
            placeholder="none"
          />
        </div>
      </WhereToGPayWrapper>
    </ContentContainer>
  )
}

const WhereToGPayWrapper = styled.div`
  text-align: center;
  .para {
    margin: 24px 0 48px;
  }
`

export default WhereToGPay
