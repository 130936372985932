import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'

const EncryptedByGoogle = () => {
  return (
    <ContentContainer padding="80px 0" mPadding="80px 24px" maxWidth="1020px">
      <EncryptedByGoogleWrapper>
        <h2>
          <strong className="poppins">Encrypted and secured by Google</strong>
        </h2>
        <div className="para">
          Google Pay protects your payment information with multiple layers of
          security, using one of the world’s most advanced security
          infrastructures to help keep your account safe.
        </div>
      </EncryptedByGoogleWrapper>
    </ContentContainer>
  )
}

const EncryptedByGoogleWrapper = styled.div`
  background-color: var(--base-navy);
  color: var(--white);
  padding: 40px;
  border-radius: 16px;
  .para {
    margin-top: 24px;
    font-size: 20px;
    line-height: 1.5;
  }
`

export default EncryptedByGoogle
