import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'

const HowToUseGPay = () => {
  return (
    <ContentContainer
      padding="80px 0"
      mPadding="80px 24px"
      maxWidth="1020px"
      background="#f8f8f8"
    >
      <HowToUseGPayWrapper>
        <h2>How to use your Google Pay</h2>
        <div className="para extra-large">
          Google Pay is a safe, fast way to pay in store and online. There are
          two ways to use your SpendOne card with Google Pay
        </div>
        <h3>Contactless pay with your Android phone</h3>
        <p>
          Google Pay works anywhere contactless payments are accepted. Just hold
          your Android phone near the reader until you see a check mark. You
          don’t even need to open the app to use it!
        </p>
        <h3>Pay as quick as you click in apps and on the web</h3>
        <p>
          Use Google Pay to check out even faster across your favourite apps and
          websites. Once you’ve added a card, you won’t need to enter your
          payment info again. Just choose Google Pay at checkout, confirm your
          purchase, and you’re all set.
        </p>
      </HowToUseGPayWrapper>
    </ContentContainer>
  )
}

const HowToUseGPayWrapper = styled.div`
  text-align: center;
  .para {
    margin-top: 24px;
  }
  h3 {
    margin-top: 80px;
  }
  p {
    margin-bottom: 32px;
  }
  @media screen and (max-width: 991px) {
    max-width: 100vw;
    padding: 80px 20px;
  }
`

export default HowToUseGPay
