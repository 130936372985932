import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import InlineIcon from '../UI/InlineIcon'
import Number1 from '../../assets/images/svg/number-1-icon.svg'
import Number2 from '../../assets/images/svg/number-2-icon.svg'
import Number3 from '../../assets/images/svg/number-3-icon.svg'

const HowToSetUpGPay = () => {
  return (
    <ContentContainer padding="80px 0" maxWidth="1020px" mPadding="80px 24px">
      <HowToSetUpGPayWrapper>
        <h2>How to setup Google Pay on your SpendOne card</h2>
        <div className="para extra-large">
          Setting up Google Pay can be done in a few easy steps:
        </div>
        <div className="flex-row">
          <Number1 />
          <p>Download the Google Pay app from Google Play</p>
        </div>
        <div className="flex-row">
          <Number2 />
          <p>Follow the instructions to add a card or another payment method</p>
        </div>
        <div className="flex-row">
          <Number3 />
          <p>Verify your card if needed. Then you’re all set!</p>
        </div>
        <div className="desktop">
          <InlineIcon iconName="Desktop" mb="0" iconMargin="0 16px 0 0">
            <div>
              Prefer to do things on desktop? Get set up at{' '}
              <a
                className="underline"
                rel="noopener noreferrer"
                target="_blank"
                href="https://pay.google.com/"
              >
                pay.google.com
              </a>
              .
            </div>
          </InlineIcon>
        </div>
      </HowToSetUpGPayWrapper>
    </ContentContainer>
  )
}

const HowToSetUpGPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .para {
    margin: 24px 0 48px;
  }
  .flex-row {
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
  .desktop {
    margin-top: 68px;
    background-color: var(--base-navy);
    color: var(--white);
    padding: 24px;
    border-radius: 8px;
  }
  .icon-wrapper {
    margin-right: 16px;
    width: 24px;
  }
`

export default HowToSetUpGPay
