import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'

const GPayTandC = () => {

  const queriedGPayTandC = useStaticQuery(graphql`
    query GPayTandC {
      contentfulGenericHeadingTextAndIcon (
        contentIdentifier: { eq: "ForGooglePay/GPayTandC" }
      ) {
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <ContentContainer
      padding="0 0 80px"
      maxWidth="1232px"
      mPadding="0 24px 80px"
    >
      <GPayTandCWrapper>
        <div 
          dangerouslySetInnerHTML={{
            __html: queriedGPayTandC.contentfulGenericHeadingTextAndIcon.content.childMarkdownRemark.html,
          }}
        />
      </GPayTandCWrapper>
    </ContentContainer>
  )
}

const GPayTandCWrapper = styled.div`
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
      &:hover {
        color: inherit;
      }
    }
  }
`

export default GPayTandC
